import { graphql } from 'gatsby'
import React, { useEffect, useState } from 'react'
import Layout from '../components/layout'
import Hero from '../components/Shared/Hero'
//import Search from '../components/Shared/Search'
//import Personas from '../components/Shared/Personas'
import Space from '../components/Shared/Space'
import Event from '../components/Shared/Event'
import Summer from '../components/Shared/Summer'
import Form from '../components/Shared/Form'
import Logement from '../components/Shared/Logement'
import Location from '../components/Shared/Location'
import Services from '../components/Shared/Services'
import CartService from '../components/Shared/CartService'
//import Gallery from '../components/Shared/Gallery'
//import Testimonial from '../components/Shared/Testimonial'
import Questionnaires from '../components/Shared/Questionnaires'
import * as styles from './styles/residence.module.scss'
import { Helmet } from 'react-helmet'
import Instagram from '../components/Shared/Instagram'
import DecoBigIllus from '../components/Illus/DecoBigIllus'
import Meta from '../components/Core/Meta'
import { useLocation } from '@reach/router'
import AnchorNav from '../components/Core/AnchorNav'
import { useTranslation } from '../hooks/useTranslation'
import BlockSeoText from '../components/Shared/BlockSeoText'
import Breadcrumb from '../components/Core/Breadcrumb'
import slugify from 'slugify'
import { head } from '../components/head'
import usePageView from '../hooks/usePageView'
import Button from "../components/Core/Button";

export const Head = head('umbracoLandingResidencePage')

export default function ResidenceLandingDetail(props: any) {
  const { data } = props
  const location = useLocation()
  const page = data.umbracoLandingResidencePage?.linkedResidence
  const landingResidenceInfo = data.umbracoLandingResidencePage
  const instagramData = data.umbracoInstagram
  const pageTitle = page?.metaTitle
    ? page?.metaTitle
    : page?.title
    ? page?.title.replace(/\n/g, '')
    : 'Residence'

  const pagePopinGeneralVisible = page?.popinGeneralVisible

  const allPageIntl = data.allPageIntl.edges
  const listingPage = data.umbracoListingResidencePage

  const intlListingPage = allPageIntl.find(
    (page: any) => page.node && page.node.name === listingPage?.name
  )?.node

  const loc = useLocation()
  const [cityNameFromUrl, setCityNameFromUrl] = useState('')
  const { t } = useTranslation('residence')



  useEffect(() => {
    window.scrollTo(0, 0)

    let baseName = location.pathname
      .replace(slugify(page.name, { lower: true }), '')
      .replace('/en', '')
      .split('/')[2]

    if (baseName) {
      baseName = `${baseName[0].toUpperCase()}${baseName
        .slice(1)
        .toLowerCase()}`
      setCityNameFromUrl(baseName.replace('-', ' '))
    }

    if (typeof window !== 'undefined' && window.document) {
      usePageView(pageTitle, window.location.pathname)
    }
  }, [])

  // console.log('---page--', page)

  const handleGoToInfo = () => {
    if(landingResidenceInfo.ctaAction && landingResidenceInfo.ctaAction == 'encre formulaire'){
      const $info = document.getElementById('landing-form')
      window.scrollTo({
        top: $info?.offsetTop,
        behavior: 'smooth',
      })
    } else {
      window.open('https://reserver.hife-coliving.fr/#/', '_blank');
    }
  }

  return (
    <>
      <Helmet>
        <body className="single-residence" />
      </Helmet>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'RealEstateAgent',
            name: page.title,
            image:
              page.imageCover && page.imageCover.media
                ? page.imageCover.media._url
                : '',
            '@id': page.title,
            url: loc?.href,
            telephone: page.telephone,
            address: {
              '@type': 'PostalAddress',
              streetAddress: page.streetAddress,
              addressLocality: page.addressLocality,
              postalCode: page.postalCode,
              addressCountry: page.addressCountry,
            },
            geo: {
              longitude: page.longitude,
              latitude: page.latitude,
            },
          }),
        }}
      ></script>
      <div className={`${styles.residenceCta} container fixed left-0 right-0 text-right top-4`}>
        <Button
            type="anchor"
            btnType="light"
            onClick={handleGoToInfo}
        >
          {landingResidenceInfo.ctaTitle && landingResidenceInfo.ctaTitle !== '' ? landingResidenceInfo.ctaTitle : t('Reserver', 'common')}
        </Button>
      </div>
      <Layout className={`landing-residence ${styles.residenceDetail}`}>
        <Hero
          title={page.title.replace(/\n/g, '<br>')}
          image={
            page.imageCover && page.imageCover.childImageSharp
              ? page.imageCover
              : page.imageCover && page.imageCover.media
              ? page.imageCover.media
              : undefined
          }
          image-mobile={page.imageCoverMobile}
          video={page.videoCover}
          customClassname="hero__landing_residence"
        >
          {/* <Search /> */}
          <DecoBigIllus className="deco" />
        </Hero>
        {/* page.items && <AnchorNav data={page.items} /> */}
        {page.items &&
          page.items.map((homeBlock: any, index: number) => {
            if (homeBlock.content) {
              const blockContent = homeBlock.content
              switch (blockContent.contentTypeAlias) {
                case 'residencePush':
                  return (
                    <>
                      {/* <Summer
                        data={{
                          contentTypeAlias: blockContent.contentTypeAlias,
                          title: blockContent.title,
                          paragraph: blockContent.subtitle,
                          link:
                          blockContent.links && blockContent.links[0]
                            ? blockContent.links[0]
                            : {
                                name: 'Contacter la residence',
                                url: '/',
                              },
                          // cta: {
                          //   title: t('Visite virtuelle', 'residence'),
                          // },
                          // virtualVisit: blockContent.virtualVisit,
                          // virtualVisitUrl: blockContent.virtualVisitUrl,
                          position: page.title,
                        }}
                        illustration={blockContent.illustration}
                        residence={page}
                        blockClassName={`summer-push landing-single ${
                          index === 0 ? 'concept-push' : ''
                        }`}
                        bg={blockContent.backgroundColor}
                        titleColor={blockContent.titleColor}
                        key={`sp-${index}`}
                        formClass="landing-form"
                      /> */}
                        <Form data={data.umbracoLandingResidencePage} />
                    </>
                  )
                case 'blockPersonas':
                  return false
                /* (
                                    <Personas
                                        data={{
                                            contentTypeAlias: blockContent.contentTypeAlias,
                                            title:
                                                blockContent.title || 'Le coliving twiste pour vous',
                                            description:
                                                blockContent.subtitle ||
                                                `Prenez le temps d’être en mouvement ! Twist by Kley, c’est le
              nouveau lieu de vie idéal des jeunes actifs pour travailler,
              découvrir, se recentrer, s'épanouir et rejoindre une véritable
              communauté !`,
                                            personas: blockContent.itemsPersona,
                                        }}
                                        residence={page}
                                        key={`p-${index}`}
                                        className="persona"
                                    />
                                )

                                 */
                case 'blockAccomodations':
                  return (
                    <Logement
                      data={blockContent}
                      key={`r-${index}`}
                      residence={page}
                    />
                  )
                case 'blockIncludedServices':
                  return (
                    <Services
                      data={blockContent}
                      key={`r-${index}`}
                      layout="landing"
                    />
                  )
                case 'blockConcept1':
                  return (
                    <Space
                      data={blockContent}
                      key={`s-${index}`}
                      className="space"
                      layout={'landing'}
                    />
                  )
                case 'blockPaidServices':
                  return (
                    <CartService
                      data={blockContent}
                      key={`cs-${index}`}
                      layout={'landing'}
                    />
                  )
                case 'imagesGallery':
                  return false /*<Gallery data={blockContent} key={`g-${index}`} /> */
                case 'blockNewEvents':
                  return <Event data={blockContent} key={`be-${index}`} />
                case 'blockLocation':
                  return (
                    <Location
                      data={Object.assign({}, blockContent, {
                        contact: page.telephone,
                      })}
                      pois={page.newPoiLists}
                      gps={{ lat: +page.latitude, lng: +page.longitude }}
                      key={`bl-${index}`}
                      className={styles.location}
                      layout={'landing'}
                    />
                  )
                case 'blockTestimonials':
                  return false /*<Testimonial data={blockContent} key={`bt-${index}`} /> */
                // case 'blockFAQ':
                //   return (
                //     <Questionnaires
                //       data={blockContent}
                //       residence={page}
                //       key={`bf-${index}`}
                //       layout={'landing'}
                //     />
                //   )
              }
            }

            return null
          })}

        {/* page.bottomSEOText && <BlockSeoText content={page.bottomSEOText} /> */}
        <Instagram data={instagramData} hidden={true} />
        {/*
        <Breadcrumb
          data={[
            {
              title:
                intlListingPage?.name || t('Listing residence', 'residence'),
              link: intlListingPage?.uri,
              id: '' + intlListingPage?.uri,
            },
            {
              title: cityNameFromUrl,
              link: location.pathname
                .replace(slugify(page.name, { lower: true }), '')
                .replace('//', '/'),
              id: location.pathname
                .replace(slugify(page.name, { lower: true }), '')
                .replace('//', '/'),
            },
            {
              title: page.title,
              link: location.pathname,
              id: '' + location.pathname,
            },
          ]}
        />
        */}
      </Layout>
    </>
  )
}

export const query = graphql`
  query ($lang: String, $pageIdentifier: String) {
    umbracoListingResidencePage(lang: { eq: $lang }) {
      name
      _url
      _urls {
        fr
        en_us
      }
    }
    umbracoLandingResidencePage(pageIdentifier: { eq : $pageIdentifier}, lang: { eq: $lang }) {
      id
      pageIdentifier
      name
      metaTitle
      metaDescription
      metaFollow
      metaIndex
      ctaTitle
      ctaAction
      bottomSEOText
      newsletterLegalLink
      newsletterLegalMore
      newsletterLegalText
      successText
      twitterCard {
        id
        url
      }
      facebookCard {
        id
        url
      }
      _url
      _urls {
        fr
        en_us
      }
      formTitle
      formDescription
      step1Title
      step1Cta
      step2Title
      step2Cta
      step3Title
      step3Cta
      linkedResidence {
        _id
        salesforceName
        salesforceId
        name
        metaTitle
        metaDescription
        metaFollow
        metaIndex
        bottomSEOText
        pageIdentifier
        twitterCard {
          id
          url
        }
        facebookCard {
          id
          url
        }
        _url
        _urls {
          fr
          en_us
        }
        title
        shortDescription
        shortName
        telephone
        streetAddress
        addressLocality
        postalCode
        addressCountry
        longitude
        latitude
        city {
          title
        }
        imageCover {
          media {
            alt
            name
            _url
          }
          # childImageSharp {
          #   id
          #   gatsbyImageData(layout: FULL_WIDTH)
          # }
        }
        imageCoverMobile {
          # childImageSharp {
          #   gatsbyImageData(layout: FULL_WIDTH)
          # }
          media {
            alt
            name
            _url
          }
        }
        videoCover {
          # childImageSharp {
          #   gatsbyImageData(layout: FULL_WIDTH)
          # }
          media {
            alt
            name
            _url
          }
        }
        tag {
          content {
            color
            title
            contentTypeAlias

          }
        }
        newPoiLists {
          content {
            title
            icon
            items {
              content {
                gps {
                  mapconfig {
                    centerCoordinates {
                      lat
                      lng
                    }
                  }
                }
                title
                address
                contact
              }
            }
          }
        }
        popinGeneralVisible
        items {
          content {
            title
            sectionTitle
            subtitle
            serviceCta
            formCta
            ctaAction
            ctaTitle
            contentTypeAlias
            colorCombination
            titleColor
            backgroundColor
            virtualVisit
            virtualVisitUrl
            virtualVisitButtonLabel
            illustration {
              src
              gatsbyImageData(width: 400, quality: 90)
            }
            links {
              url
              name
              type
            }
            link {
              url
              name
              type
            }
            itemsGallery {
              src
              gatsbyImageData(width: 1560, quality: 90)
              media {
                alt
                name
              }
            }
            titleGallery
            backgroundVimeoId
            itemsPaidServices {
              name
              title
              subtitle
              shortName
              contentTypeAlias
              thumbnail {
                src
                gatsbyImageData(width: 1100, quality: 90)
              }
            }
            itemsIncludedServices {
              name
              title
              subtitle
              contentTypeAlias
              cTATitle
              ctaLink {
                udi
                url
                name
                type
              }
            }
            itemsFaq {
              name
              answer
              question
              contentTypeAlias
            }
            itemsPersona {
              name
              title
              subtitle
              frameColor
              contentTypeAlias
              link {
                url
                name
              }
              thumbnail {
                gatsbyImageData(width: 180, quality: 90)
                media {
                  alt
                }
              }
            }
            itemsAccomodation {
              name
              title
              subtitle
              priceFrom
              priceFromValue
              roomSize
              contentTypeAlias
              shortDescription
              accomodationTag
              accomodationType {
                accomodationTypeTitle
              }
              thumbnailImages {
                gatsbyImageData
                media {
                  alt
                  name
                  _url
                }
              }
              details {
                title
                boTitle
                subtitle
              }
              accommodationDetailModalDescription
              accommodationDetailModal {
                content {
                  title
                  description
                }
              }
            }
            newItemsAccomodation {
              content {
                title
                subtitle
                priceFrom
                priceFromValue
                roomSize
                contentTypeAlias
                shortDescription
                accomodationTag
                accomodationType {
                  accomodationTypeTitle
                }
                thumbnailImages {
                  gatsbyImageData(width: 1560, quality: 90)
                  media {
                    alt
                    name
                    _url
                  }
                }
                details {
                  title
                  boTitle
                  subtitle
                }
                accommodationDetailModal {
                  content {
                    title
                    description
                  }
                }
                accommodationDetailModalDescription
                hideOnHifeWebsite
              }
            }
            compareAccomodations {
              content {
                title
                tag
                surface
                servicesAndOptions
                plus
                capacity
                bed
                thumbnail {
                  src
                }
              }
            }
            linkFaq {
              url
              name
              type
            }
            spaces {
              title
              subtitle
              name
              mainColor
              logo {
                src
              }
              medias {
                src
                gatsbyImageData(width: 880, quality: 90)
              }
            }
            eventsList {
              title
              subtitle
              bg
              thumbnail {
                media {
                  alt
                  _url
                }
                gatsbyImageData(width: 880, quality: 90)
              }
              backgroundVimeoId
              category {
                color
                title
              }
              price
            }
            keyPoints {
              content {
                title
                paragraph
              }
            }
            acces {
              content {
                title
                subtitle
                transportType
              }
            }
            illustration {
              src
            }
            linkFaq {
              name
              url
            }
            testimonials {
              content {
                media {
                  src
                  gatsbyImageData(width: 1560, quality: 90)
                }
                author
                authorTitle
                paragraph
                vimeoId
              }
            }
          }
        }
      }
    }
    umbracoInstagram(lang: { eq: $lang }) {
      title
      hashtag
      instagramHandle
      instagramLinkButton {
        url
        name
        type
      }
      enabled
    }
    allPageIntl {
      edges {
        node {
          name
          uri
          alternates {
            name
            uri
            lang
          }
        }
      }
    }
  }
`

